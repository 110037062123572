import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule }    from '@angular/common/http';

import {MatButtonModule, MatCheckboxModule} from '@angular/material';
import {ComponentMaterialModule} from './material-module'
import { from } from 'rxjs';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MapComponent } from './map/map.component';
import { HeaderComponent } from './header/header.component';

import {UndermapReseauxService} from './services/undermap.reseaux.service'
import { MapGeneralService } from './services/map.general.service'
import { AuthService } from './services/auth.service'
import { MesureComponent } from './map/mesure/mesure.component';
import { ImpressionComponent } from './map/impression/impression.component'
import { LoginComponent } from './login/login.component' 
import { AuthGuard } from './auth.guard';
import { IsAuthGuard } from './isauth.guard';
@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    HeaderComponent,
    MesureComponent,
    ImpressionComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentMaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule ,
    FontAwesomeModule,
  ],
  providers: [
    MapGeneralService,
    UndermapReseauxService,
    AuthService,
    AuthGuard,
    IsAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

//ng generate component header/header
//ng generate component login
//ng generate component map
//ng generate component map/mesure
//ng generate component map/impression
//ng generate service services/auth
//ng generate service services/backend-api
//ng generate service services/map.general
//ng generate service services/undermap.tarvel
// ng generate guard  guards/auth
// ng generate guard  guards/isauth
// ng serve --host 192.168.1.179