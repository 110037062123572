import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry,tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  results:Object[];
  loading:boolean;
  private headers: HttpHeaders = new HttpHeaders({});
  private headers_qgis_serveur: HttpHeaders = new HttpHeaders({});

  constructor(
    private http: HttpClient
    ) { 
    this.loading = false; 
    this.headers.append('Content-Type','application/x-www-form-urlencoded');
    this.headers.append('Content-Type','application/json');
    // this.headers_nodejs.append('Content-Type','application/json');
    }

  public getJSON(path): Observable<any> {
      return this.http.get(path);
  }

  getGml(url){
    return this.http.get(url, {responseType: 'text',headers:this.headers_qgis_serveur})
    .pipe(
      tap( // Log the result or error
        // data => console.log(1),
        // error => console.log(error)
      )
    );
  }

  // getInternalFile(word) {
  //   let promise = new Promise((resolve, reject) => {
  //     this.http.get(word,{headers: this.headers})
  //       .toPromise()
  //       .then(
  //         res => {  
           
  //           resolve(res);
  //         },
  //         msg => { // Error
  //         reject(msg);
  //         }
  //       );
  //   });
  //   return promise;
  // }

}
