import { Component, OnInit ,AfterViewChecked,ViewChild,AfterViewInit} from '@angular/core';
import { config } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent  {

  
  // private MapComp: MapComponent;
  config : {}
  constructor(
  ) { 
    
  }

  setConfigValue(value){
    this.config = value
    
  }
  ngOnInit() {
    // console.log(this.MapComponent)
  }
  
}
