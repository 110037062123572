import { Injectable } from '@angular/core';
import {  METERS_PER_UNIT } from 'ol/proj.js';
import ImageLayer from 'ol/layer/Image.js';
import ImageWMS from 'ol/source/ImageWMS.js';
@Injectable()
export class MapGeneralService {

  public map: any;
  zoom_actuel = 0
  constructor() { }
  
  wmsLyon = new ImageLayer({
    source: new ImageWMS({
      url: 'https://download.data.grandlyon.com/wms/grandlyon',
      params: { 'LAYERS': 'Ortho2015_vue_ensemble_16cm_CC46' },
      // serverType: 'qgis',
      crossOrigin: 'anonymous',
      transition: 0,
      attributions: '© <a target="_blank" href="https://data.beta.grandlyon.com/fr/datasets/orthophotographie-2015-metropole-lyon/info">Data Grand Lyon</a> Orthophotographie 2015 de la Métropole de Lyon ',
    }),
    // nom: 'lyonSource'
  });

  setMap(map) {
    this.map = map;
    this.map.getView().on('change:resolution',()=>{
      this.zoom_actuel = this.map.getView().getZoom()
    })
   
  }

  mapScale (dpi = 25.4 / 0.28) {
    var unit = this.map.getView().getProjection().getUnits();
    var resolution = this.map.getView().getResolution();
    var inchesPerMetre = 39.37;

    return resolution * METERS_PER_UNIT[unit] * inchesPerMetre * dpi;
  }

  getZoomFromScale(scale){
    var units = this.map.getView().getProjection().getUnits();
    var dpi = 25.4 / 0.28;
    var mpu = METERS_PER_UNIT[units];
    var resolution = scale/(mpu * 39.37 * dpi);
    return this.map.getView().getZoomForResolution(resolution);
  }

  changer_fond_carte(nom_fond) {

    var layer_to_remove;

    for (let index = 0; index < this.map.getLayers().getArray().length; index++) {
      var layer = this.map.getLayers().getArray()[index]
      if (layer.get('type') == 'fond') {
        layer.setVisible(false)
      }
      if (layer.get('nom') == nom_fond && layer.get('type') == 'fond') {
        layer_to_remove = layer
      }
    }

    if (nom_fond != 'aucun') {
      layer_to_remove.setVisible(true)
    }

  }
  getLayerByAttr(layername, attr,layer_group) {
    var layer_to_remove;

    if(layer_group ){
      var all_layers =  this.map.getLayers().getArray()
    }else{
      var all_layers =  this.map.getLayerGroup().getArray()
    }

    for (let index = 0; index < all_layers.length; index++) {
      var layer = all_layers[index]

      if (layer.get(attr) == layername) {
        layer_to_remove = layer
      }

    }

    return layer_to_remove
  }

  set_couche_visible(couche) {
    var layer_to_remove;
    var layer_group = couche.type

    if(layer_group == 'groupe'){
      var all_layers =  this.map.getLayers().getArray()
    }else{
      var all_layers =  this.map.getLayers().getArray()
      // var all_layers =  this.map.getLayerGroup().getArray()
    }

    for (let index = 0; index < all_layers.length; index++) {
      var layer = all_layers[index]

      if (layer.get('type_layer') == 'reseaux') { 
        if (layer.get('identifiant_qgis') == couche.identifiant_qgis) {
          layer_to_remove = layer
        }
      } else {
        if (layer.get('nom') == couche.nom ) {
          layer_to_remove = layer
        }
      }

    }
    
    if (layer_to_remove.getVisible()) {
      layer_to_remove.setVisible(false)
      couche.visible = false
    } else {
      layer_to_remove.setVisible(true)
      couche.visible = true
    }
    
  }

}
