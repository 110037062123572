import { Component, OnInit } from '@angular/core';
import { FormControl,FormGroup } from '@angular/forms';
import {  boundingExtent ,getArea,buffer,getSize} from 'ol/extent.js';
import Polygon from 'ol/geom/Polygon.js';
import Draw, {createRegularPolygon, createBox} from 'ol/interaction/Draw.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {  METERS_PER_UNIT,getPointResolution,transformExtent,get as getProjection } from 'ol/proj.js';

import { map } from 'rxjs/operators';
import * as turf from '@turf/turf'
import * as $ from 'jquery';

import { MapGeneralService } from '../../services/map.general.service'
import {UndermapReseauxService} from '../../services/undermap.reseaux.service'

import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-impression',
  templateUrl: './impression.component.html',
  styleUrls: ['./impression.component.scss']
})


export class ImpressionComponent implements OnInit {

  public map: any;

  fa = {
    'faWindowClose': faWindowClose,
  }

  extent_carte_situation
  echelle = 500
  echelles:Array<Object>
  extent_feature
  url_qgis
  toogle = false
  echelle_carte
  identifiant_fdp_qgis
  layout={
    'templates':[{
      'nom':'A3',
      'nom_template':'default',
      'size':[351.6,280.8],
    }],
    'grid':true
  }

  layout_choisi={
    'templates':{},
    'description':'',
    'titre':'',
    'rotation':0,
  }
  impressionControl = new FormGroup({
    'rotation': new FormControl(0, []),
  });

  constructor(
    private MapGeneralService: MapGeneralService,
    private UndermapReseauxService: UndermapReseauxService,
  ) { }

  ngOnInit() {
    this.impressionControl.controls['rotation'].valueChanges.subscribe(value => {
      // this.map.getView().setRotation((value)* Math.PI / 180)
      this.layout_choisi.rotation = value
    });
  }

  setMap(map) {
    this.map = map;

    this.map.on('click', (evt) => {
      // this.onpointerMove(evt)
      // var e = map.getEventPixel(evt)
      // var e = [evt.detail.clientX - 356+54, evt.detail.clientY-39]
      var pixel = evt.pixel
      var coordinate = evt.coordinate
      console.log(pixel)

    });
  }

  getRotate(){ 
    return 'rotate('+this.layout_choisi.rotation+'deg)'
    // return 'rotate(0deg)'
  }
  
  initialise_impression(url_qgis,extent,identifiant_fdp_qgis){
    this.url_qgis = url_qgis
    this.identifiant_fdp_qgis = identifiant_fdp_qgis
    this.extent_carte_situation = extent
    this.echelles = [{
        'nom':'1/1000',
        'echelle':1000,
      },{
        'nom':'1/500',
        'echelle':500,
      },
      {
        'nom':'1/200',
        'echelle':200,
      },
      {
        'nom':'1/100',
        'echelle':100,
      }
      ,
      {
        'nom':'1/50',
        'echelle':50,
      }
    ]

  }

  echelle_change(){
    this.recomputeBox()
  }

  recomputeBox(){
    this.echelle_carte = Math.round(this.MapGeneralService.mapScale())
    var mapSize = this.map.getSize()
    var view = this.map.getView();
    var coords = view.getCenter();
    var center_pixel = this.map.getPixelFromCoordinate(coords);
    var resolution = view.getResolution();    
    var projection = view.getProjection();
    var resolutionAtCoords = getPointResolution(projection,resolution, coords);
    
    // console.log(resolution,resolutionAtCoords,resolution/resolutionAtCoords,'resolution')
    
    // var mapPointResolution = (this.echelle_carte * 25.4) / (resolution * 1000);

    // console.log(this.layout_choisi.templates['size'])
    // var width1 =  281 * resolution / 25.4;
    var width2 = (this.echelle * this.layout_choisi.templates['size'][0]) / (resolutionAtCoords * 1000) ;
    var height2 = (this.echelle * this.layout_choisi.templates['size'][1]) / (resolutionAtCoords * 1000);

    $('#PrintFrame').width(width2).height(height2);

    $("#PrintFrame").css("left", mapSize[0]/2 - width2/2 - 356/2 )
    $("#PrintFrame").css("top", mapSize[1]/2 - height2/2 )

    
    // console.log(width2,height2)
  }

  toogle_impression(){
    
    if (!this.toogle) {
      this.start_impression()
    } else {
      this.stop_impression()
    }    
    
  }

  start_impression(){
    this.toogle = true
    this.layout_choisi.templates = this.layout.templates[0]
    this.recomputeBox()
    this.map.getView().on('change:resolution',()=>{
      this.recomputeBox()
    })
    
  }
  stop_impression(){
    this.toogle = false
  }


  imprimer(){
    
    
    var layers_active = this.UndermapReseauxService.get_reseaux_to_print()
    var layers_to_print = layers_active.join(',')

    var styles = []
    for (let index = 0; index < layers_active.length; index++) {
      if ( layers_active[index] != this.identifiant_fdp_qgis){
        styles.push('défaut')
      }else{
        styles.push('gray')
      }
    }

    var view = this.map.getView();
    var coords = view.getCenter();
    var resolution = view.getResolution();  
    var projection = view.getProjection();
    var resolutionAtCoords = getPointResolution(projection,resolution, coords);
    var center_pixel = this.map.getPixelFromCoordinate(coords);
    var rect = document.getElementById('PrintFrame').getBoundingClientRect();
    var width = $('#PrintFrame').width()
    var height = $('#PrintFrame').height()

    var facteur_echelle = resolution/resolutionAtCoords
    // console.log(left,rect.left);
    // console.log(rect.left- 356,rect.top);
    
  // Converts from degrees to radians.
  var deg_to_radians = function(degrees) {
    return degrees * Math.PI / 180;
  };
  // Converts from radians to degrees.
  var radians_to_deg = function(radians) {
    return radians * 180 / Math.PI;
  };

   var rayon = Math.sqrt(Math.pow(width, 2)/4 + Math.pow(height, 2)/4)
   var angle_diagonale_rectangle = radians_to_deg(Math.atan( height/width ) +Math.PI)
   var alpha = this.layout_choisi.rotation
   var theta = angle_diagonale_rectangle-Math.abs(alpha)
  // console.log(angle_diagonale_rectangle,Math.abs(alpha),theta) *resolution/resolutionAtCoords
  // console.log(rayon*Math.abs(Math.cos(deg_to_radians(theta))),rayon*Math.abs(Math.sin(deg_to_radians(theta))))
  //  var x0 = center_pixel[0]-356/2-rayon*Math.abs(Math.cos(deg_to_radians(theta)))

  //  var y0 = center_pixel[1] - rayon*Math.abs(Math.sin(deg_to_radians(theta)) )

  //  var x1 = (center_pixel[0]-356/2+rayon*Math.abs(Math.cos(deg_to_radians(theta))))
  //  var y1 = (center_pixel[1]+rayon*Math.abs(Math.sin(deg_to_radians(theta)) ))
  
  //   // var pixelA0 = [rect.left - 356,rect.top] rect.left - 356-x
  //   var pixelA0 = [x0,y0]
  //   // var pixelA1 = [pixelA0[0]+width,pixelA0[1]+height]
  //   var pixelA1 = [x1,y1]

  //   var pixelA2 = [x1,y0]

  //   var pixelA3 = [x0,y1]

  // if (alpha>=0) {
    // var x0 = height*Math.cos(deg_to_radians(90-alpha)) + rect.left -365
    // var y0 = rect.top

    // var x3 = rect.left -365
    // var y3 = height*Math.sin(deg_to_radians(90-alpha)) + rect.top

    // var x2 = rect.right -365
    // var y2 = width*Math.sin(deg_to_radians(alpha)) + rect.top

    // var x1 =  width*Math.cos(deg_to_radians(alpha)) + rect.left -365
    // var y1 = rect.bottom

    var x0 = center_pixel[0]-356/2-width/2
    var y0 = center_pixel[1]-height/2

    var x1 = center_pixel[0]-356/2+width/2
    var y1 = center_pixel[1]+height/2

    var pixelA0 = [x0,y0]
    var pixelA1 = [x1,y1]
    // var pixelA2 = [x2,y2]
    // var pixelA3 = [x3,y3]
    
    // console.log(pixelA2,pixelA1)
  // }

    var coordA0 = this.map.getCoordinateFromPixel(pixelA0)
    var coordA1 = this.map.getCoordinateFromPixel(pixelA1)
    // var coordA2 = this.map.getCoordinateFromPixel(pixelA2)
    // var coordA3 = this.map.getCoordinateFromPixel(pixelA3)
    // console.log(pixelA0)
    // console.log(pixelA1)
    // console.log(pixelA2)
    // console.log(pixelA3)
    var print_extent = boundingExtent([coordA0,coordA1]) 
    // console.log(print_extent,[coordA0[0],coordA1[1],coordA1[0],coordA0[1]]); facteur_echelle
    // var print_extent = [coordA0[0],coordA1[1],coordA1[0],coordA0[1]]
    var surface_extent = getSize(print_extent)
    console.log(print_extent,surface_extent[0]/facteur_echelle,surface_extent[1]/facteur_echelle);

    // var poly = turf.toWgs84(turf.bboxPolygon(print_extent));
    // console.log(poly)
    // var rotatedPoly = turf.toMercator(turf.transformRotate(poly, alpha));
    // var print_extent = turf.bbox(rotatedPoly);
    // console.log(getSize(print_extent),1);
    // print_extent = print_extentB turf.toWgs84
    // var print_extent =transformExtent(boundingExtent([coordA0,coordA1]),getProjection('EPSG:3857'),getProjection('EPSG:3946'))
    // console.log( boundingExtent([coordA0,coordA1]),print_extent)

    
    
    var mapScale = this.echelle 
    var grid_interval = mapScale/10;
    if (mapScale == 200) {
      for (let index = 0; index < styles.length; index++) {
        if (styles[index] == 'défaut'){
          styles[index] = '250'
        };
      }
    }
    
    
    var printUrl = this.url_qgis+'&REQUEST=GetPrint&FORMAT=pdf&CRS=EPSG:3857&DPI='+'800'+'&TEMPLATE='+this.layout_choisi.templates['nom_template']+'&map0:extent='+print_extent.join(',')+'&map0:scale='+mapScale*facteur_echelle+'&map0:LAYERS='+layers_to_print+'&map0:STYLES='+styles.join(',')+'&nom_format='+this.layout_choisi.templates['nom']+'&echelle='+'1/'+mapScale+'&epsg='+'3857';
    
    if (this.layout.grid) {
      printUrl = printUrl +'&map0:grid_interval_x='+grid_interval+'&map0:grid_interval_y='+grid_interval
    }

    // printUrl = printUrl +'&map1:extent='+print_extent.join(',')+'&map1:scale='+mapScale+'&map1:LAYERS='+'Fond de plan'
    var rapport_bbox = getArea(this.extent_carte_situation)/getArea(print_extent)
    // console.log(rapport_bbox)
    var extent_map_situation = buffer(print_extent,this.echelle/2)
    if (this.echelle < 200) {
      var extent_map_situation = buffer(print_extent,250/2)
    }
    printUrl = printUrl +'&map1:extent='+extent_map_situation.join(',')+'&map1:LAYERS='+this.identifiant_fdp_qgis+'&map1:STYLES=gray'
    // printUrl = printUrl +'&map1:extent='+extent_map_situation.join(',')+'&map1:LAYERS=emprise'
    
    printUrl = printUrl +'&titre='+encodeURI(this.layout_choisi.titre)+'&description='+encodeURI(this.layout_choisi.description)

    printUrl = printUrl +'&map0:rotation='+this.layout_choisi.rotation*-1
    // &map0:rotation=18
   
    this.goToLink(printUrl)
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
