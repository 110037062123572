import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service'
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  mdp=''
  constructor(
    private AuthService : AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  login(){
    var response = this.AuthService.loggin(this.mdp)
    if (response) {
      this.router.navigate(['map'])
    }else{
      this.snackBar.open('Mot de passe incorrect', 'Fermer', {
        duration: 20000,
      });
    }
  }
  display_login(){
    if(this.mdp.length < 4){
      return true
    }else{
      return false
    }
  }
}
