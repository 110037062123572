import { Injectable,Output } from '@angular/core';


import { Circle as CircleStyle, Fill, Stroke, Text, RegularShape } from 'ol/style.js';
import Style from 'ol/style/Style';
import MultiLineString from 'ol/geom/MultiLineString';
import {  boundingExtent } from 'ol/extent.js';
const maxResolution = 0.05;

import * as turf from '@turf/turf'

import { MapGeneralService } from './map.general.service'

@Injectable({
  providedIn: 'root'
})
export class UndermapReseauxService {

  public config: any;
  public map: any;
  this = this
  resolution
  // impressionControl = new FormControl(''); //[formControl]="favoriteColorControl"

  

  constructor(
    private MapGeneralService: MapGeneralService
  ) { 
    this.lineStyleFunction =this.lineStyleFunction.bind(this)
  }

  setConfig(config,map) {
    this.config = config;
    this.map = map;
  }

  getColorFromTypeRsx(type_reseau){
    for (let index = 0; index < this.config["reseaux"].length; index++) {
      if(this.config["reseaux"][index]["nom"] ==type_reseau ){
        var couleur =  this.config["reseaux"][index]["couleur"]
      }
    }
    if (couleur){
      return couleur
    }else{
      return '#000'
    }
  }

  type_reseauPartiallySelected(type_reseau) {
    var statut = []
    for (let index = 0; index < type_reseau.reseaux.length; index++) {
      if (type_reseau.reseaux[index]['visible'] == false) {
        statut.push(1)
      }
    }

    if (statut.length != 0) {
      if (type_reseau.reseaux.length == statut.length) {
        return false
      } else {
        return true
      }

    } else {
      return false
    }

  }

  type_reseauAllSelected(type_reseau) { 
    var statut = true
    for (let index = 0; index < type_reseau.reseaux.length; index++) {
      if (type_reseau.reseaux[index]['visible'] == false) {
        statut = false
      }
    }
    return statut
  }

  getText(feature, resolution) {

    if (typeof feature.get('Diametre') != 'object') {
      var text = feature.get('Classe') + ' Ø ' + feature.get('Diametre') + ' mm';
    } else {
      var text = feature.get('Classe') + ''
    }

    // var text = "4545";

    if (resolution > maxResolution) {
      text = '';
    }
    // console.log(resolution)
    return text;
  };

  createTextStyle(feature, resolution) {

    if (typeof feature.get('Diametre') != 'object') {
      var diametre = (parseFloat(feature.get('Diametre')) / 100) * 2 + 2
    } else {
      var diametre = 2
    }

    this.resolution = resolution
    var align = "center";
    var baseline = "top";
    var size = "16px";
    var offsetX = 0;
    var offsetY = diametre/2 + 8;
    var weight = "bold";
    var placement = "line";
    // var maxAngle = dom.maxangle ? parseFloat(dom.maxangle.value) : undefined;
    // if (dom.font.value == '\'Open Sans\'' && !openSansAdded) {
    //   var openSans = document.createElement('link');
    //   openSans.href = 'https://fonts.googleapis.com/css?family=Open+Sans';
    //   openSans.rel = 'stylesheet';
    //   document.getElementsByTagName('head')[0].appendChild(openSans);
    //   openSansAdded = true;
    // }
    var font = weight + ' ' + size + ' ' + "Arial";
    var fillColor = feature.get('couleur');
    var outlineColor = feature.get('couleur');
    var outlineWidth = 2;

    return new Text({
      textAlign: align,
      textBaseline: baseline,
      font: font,
      text: this.getText(feature, resolution),
      fill: new Fill({ color: fillColor }),
      // stroke: new Stroke({color: outlineColor, width: outlineWidth}),
      offsetX: offsetX,
      offsetY: offsetY,
      placement: placement,
    });
  };

  chunkArray(myArray, chunk_size){
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      var myChunk = myArray.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  lineStyleFunction(feature, resolution){

    if (typeof feature.get('Diametre') != 'object') {
      var diametre = (parseFloat(feature.get('Diametre')) / 100) * 2 + 2
    } else {
      var diametre = 2
    }

    if (feature.get('Abandon') == 0) {
      var abandon = [1]
    } else {
      var abandon = [80, 10, 10, 10]

    }

    if (resolution > maxResolution) {
      diametre = 1
    }

    var bstyle = [
      new Style({
        stroke: new Stroke({
          color: feature.get('couleur'),
          width: diametre,
          lineDash: abandon
        }),
        text: this.createTextStyle(feature, resolution)
      }),
      new Style({
        text: this.createTextStyle(feature, resolution),
        geometry: (feature) => {
          var extent_vieuw = this.map.getView().calculateExtent()
          var extent_feature = boundingExtent(feature.getGeometry().getCoordinates()[0])

          var poly_view = turf.bboxPolygon(extent_vieuw);
          var multiLine = turf.lineString(feature.getGeometry().getCoordinates()[0]);

          if ((turf.booleanContains(poly_view, multiLine) || turf.booleanCrosses(poly_view, multiLine)) && this.resolution < maxResolution) {

            var coordinates = feature.getGeometry().getCoordinates()[0];

            var multi_ligne = this.chunkArray(coordinates, 2)


            for (let index = 0; index < multi_ligne.length; index++) {
              if (multi_ligne[index].length == 1) {
                multi_ligne[index].push(multi_ligne[index - 1][1])
              }

            }

            var grande_lignes = []

            for (let index = 0; index < multi_ligne.length; index++) {

              var line = turf.toWgs84(turf.lineString(multi_ligne[index]));
              var length = turf.length(line, { units: 'kilometers' });
              // moins de 3.5 m on affiche rien
              if (length < 0.0035) {

                multi_ligne.splice(index, 1)
                index--;
              }

            }
            return new MultiLineString(multi_ligne);
          }
        }
      })
    ];


    return bstyle
  }
 
  set_all_couche_visible(type_reseau) {
    var bool = this.type_reseauAllSelected(type_reseau)

    for (let index = 0; index < type_reseau.reseaux.length; index++) {
      if (bool) {
        var layer = this.MapGeneralService.getLayerByAttr(type_reseau.reseaux[index]['identifiant_qgis'], 'identifiant_qgis',true)
        if (layer) {
          layer.setVisible(false)
          type_reseau.reseaux[index]['visible'] = false
        }
      } else {
        var layer = this.MapGeneralService.getLayerByAttr(type_reseau.reseaux[index]['identifiant_qgis'], 'identifiant_qgis',true)
        if (layer) {
          layer.setVisible(true)
          type_reseau.reseaux[index]['visible'] = true
        }
      }
    }
  }

  get_reseaux_to_print(){
    var identifiant_qgis_rsx = []
    for (let index = 0; index < this.config["reseaux"].length; index++) {
      for (let j = 0; j < this.config["reseaux"][index]["reseaux"].length; j++) {
        if (this.config["reseaux"][index]["reseaux"][j]['visible']) {
          var identifiant_qgis = this.config["reseaux"][index]["reseaux"][j]['identifiant_qgis']
          identifiant_qgis_rsx.push(identifiant_qgis)
        }
      }
    }

    var layer = this.MapGeneralService.getLayerByAttr('fdp', 'type_layer',true)
    if (layer && layer.getVisible()) {
      identifiant_qgis_rsx.push(this.config.identifiant_fdp_qgis)
    }
    
    return identifiant_qgis_rsx
  }

}
