import { Component, ViewChild, OnInit, AfterViewChecked, Input, EventEmitter, AfterViewInit, NgZone } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BackendApiService } from '../services/backend-api.service'
import { MapGeneralService } from '../services/map.general.service'


import {UndermapReseauxService} from '../services/undermap.reseaux.service'
import { HeaderComponent } from '../header/header.component';
import { MesureComponent } from './mesure/mesure.component';
import { ImpressionComponent } from './impression/impression.component'

import 'ol/ol.css';
import { Map, View } from 'ol';
import OSM from 'ol/source/OSM';
import { getWidth, boundingExtent, getTopLeft } from 'ol/extent.js';
import TileLayer from 'ol/layer/Tile.js';
import { Group as LayerGroup,Vector as VectorLayer } from 'ol/layer.js';
import { transform as Transform, fromLonLat, get as getProjection } from 'ol/proj.js';

import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import ImageLayer from 'ol/layer/Image.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import TileImage from 'ol/source/TileImage'
import GeoJSON from 'ol/format/GeoJSON.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';

import VectorSource from 'ol/source/Vector.js';
import { Circle as CircleStyle, Fill, Stroke, Text, RegularShape } from 'ol/style.js';
import Style from 'ol/style/Style';
import BaseObject from 'ol/Object';
import MultiLineString from 'ol/geom/MultiLineString';
import LineString from 'ol/geom/LineString';
import Overlay from 'ol/Overlay.js';
import WFS from 'ol/format/WFS';
import BingMaps from 'ol/source/BingMaps.js';
import { unByKey } from 'ol/Observable.js';
import { getArea, getLength } from 'ol/sphere.js';
import { Polygon } from 'ol/geom.js';
import Draw from 'ol/interaction/Draw.js';
import Zoom from 'ol/control/Zoom';
import ExtentInteraction from 'ol/interaction/Extent.js';
import {platformModifierKeyOnly} from 'ol/events/condition.js';
import * as turf from '@turf/turf'
import {defaults as defaultControls, Attribution} from 'ol/control.js';
import { faPlus, faCompressArrowsAlt, faMinus, faLayerGroup, faRuler,faPrint } from '@fortawesome/free-solid-svg-icons';

import * as $ from 'jquery';
var attribution = new Attribution({
  collapsible: false
});
const map = new Map({
  target: 'map',
  maxTilesLoading: 50,
  loadTilesWhileAnimating: true,
  loadTilesWhileInteracting: true,
  layers: [
    new TileLayer({
      source: new OSM(),
      nom: 'fond',
      type: 'fond',
      attribution:'© <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      visible: false
    })
  ],
  controls: defaultControls({attribution: false,zoom:false}).extend([attribution]),
  view: new View({
    center: fromLonLat([5, 45]),
    zoom: 14,
    minZoom: 14,
  })
});

@Component({
  selector: 'app-root',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewChecked, AfterViewInit {

  constructor(
    private _ngZone: NgZone,
    private BackendApiService: BackendApiService,
    private MapGeneralService: MapGeneralService,
    private UndermapReseauxService: UndermapReseauxService,
  ) {
    (function (mouseStopDelay) {
      var timeout;
      document.addEventListener('mousemove', function (e) {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          var event = new CustomEvent("mousestop", {
            detail: {
              clientX: e.clientX,
              clientY: e.clientY
            },
            bubbles: true,
            cancelable: true
          });
          e.target.dispatchEvent(event);
        }, mouseStopDelay);
      });
    }(100));
    
  }

  fa = {
    'faMinus': faMinus,
    'faCompressArrowsAlt': faCompressArrowsAlt,
    'faPlus': faPlus,
    'faLayerGroup': faLayerGroup,
    'faRuler': faRuler,
    'faPrint':faPrint
  }
  faCompressArrowsAlt = faCompressArrowsAlt
  config = {}
  couche_fdp = {}
  feature_mousemove = {}
  feature_mousemove_overlay
  timer_mousemove
  fond_carte = "aeriene"
  resolution
  emprise = {}
  status_mesure = false
  big_loading = {
    'active': true,
    'display': true,
  }

  // @ViewChild(HeaderComponent)
  @ViewChild(ImpressionComponent)ImpressionComp:ImpressionComponent
  @ViewChild(MesureComponent)mesureComp:MesureComponent

  // private headerComp: HeaderComponent;
  // private ImpressionComp: ImpressionComponent;
  // private mesureComp: MesureComponent;


  @ViewChild('sidenavStart') sidenav_start: MatSidenav;


  close() {
    this.sidenav_start.close();
  }

  panelOpenState = false;

  ngAfterViewInit() {
    var zooms = new Zoom({
      'target': 'zooms',
      'zoomInLabel': document.getElementById('plus'),
      'zoomOutLabel': document.getElementById('moins'),
      'zoomInTipLabel': 'Zoomer',
      'zoomOutTipLabel': 'Dézoomer'
    })
    zooms.setMap(map)
    
    // $('#map').bind('contextmenu', function(e) {
    //   return false;
    // });

  }




  onpointerMove(evt) { 

 

    var e = [evt.detail.clientX - 356, evt.detail.clientY]
    // var e = map.getEventPixel(evt)
    var feature = map.forEachFeatureAtPixel(e,
      function (feature, layer) {
        return feature;
      }, {
        hitTolerance: 5
      });

    var layer = map.forEachLayerAtPixel(e,
      function (layer) {
        return layer;
      }, {
        hitTolerance: 5
      });
      
    
    if (layer && layer.get('type_layer') == 'reseaux') {
      if (layer instanceof VectorLayer && feature){
     
        this.timer_mousemove = setTimeout(() => {

          if (typeof feature.get('Diametre') != 'object') {
            var diametre = feature.get('Diametre') + ' mm'
          } else {
            var diametre = 'Non défini'
          }

          if (feature.get('Abandon') == 0) {
            var abandone = "Non"
          } else {
            var abandone = 'Oui'
          }

          this.feature_mousemove = {
            'reseaux': layer.get('reseaux'),
            'exploitant': layer.get('nom'),
            'classe': feature.get('Classe'),
            'couleur': feature.get('couleur'),
            'diametre': diametre,
            'abandone': abandone,
          }

          var coordinate = map.getCoordinateFromPixel(e)
          this.feature_mousemove_overlay.setPosition(coordinate);
          document.getElementById('resume_reseau').style.display = 'block'
        }, 1000);

      }else if(layer instanceof TileLayer){
        var identifiant_qgis = layer.get('identifiant_qgis').replace('_web', '')
        var wmsSource = new ImageWMS({
          url: this.config['url_qgis_serveur'],
          params: { 'LAYERS': identifiant_qgis },
          serverType: 'qgis',
          crossOrigin: 'anonymous',
          transition: 0
        });
        var viewResolution = map.getView().getResolution();
        var url = wmsSource.getGetFeatureInfoUrl(map.getCoordinateFromPixel(e), viewResolution, 'EPSG:3857')+"&INFO_FORMAT=application/json&FI_LINE_TOLERANCE=17"
        
        // console.log(url) &WITH_GEOMETRY=TRUE 
        this.timer_mousemove = setTimeout(() => {

          $.get(url, (data) => {
            var properties = data.features[0]['properties']
            var exploitant = properties['Exploitant']
            var classe = properties['Classe']
            // var diametre = properties['Diametre (mm)']
            var abandon = properties['Abandon']
            var reseaux = properties['Reseau']

            if (abandon == 0) {
              var abandone = "Non"
            }else {
              var abandone = 'Oui'
            }
            
            if (properties['Diametre (mm)'] != null) {
              var diametre = properties['Diametre (mm)'] + ' mm'
            } else {
              var diametre = 'Non défini'
            }

            this.feature_mousemove = {
              'reseaux': reseaux,
              'exploitant': exploitant,
              'classe': classe,
              'couleur': this.UndermapReseauxService.getColorFromTypeRsx(reseaux),
              'diametre': diametre,
              'abandone': abandone,
            }
  
            var coordinate = map.getCoordinateFromPixel(e)
            this.feature_mousemove_overlay.setPosition(coordinate);
            document.getElementById('resume_reseau').style.display = 'block'

          })

        }, 1000);
        

      }
    } else {

      // this._ngZone.run(() => {
      // console.log(44)
      if (this.timer_mousemove) {
        clearTimeout(this.timer_mousemove)
        document.getElementById('resume_reseau').style.display = 'none'
      }

      // })

    }

    // })
  }


  ngOnInit() {
    map.setTarget('map1')
    map.setTarget('map')
    this.MapGeneralService.setMap(map)
    this.ImpressionComp.setMap(map)
    
    
    map.getViewport().addEventListener('mousestop', (evt) => {
      this.onpointerMove(evt)
      // var e = map.getEventPixel(evt)
      // var e = [evt.detail.clientX - 356+54, evt.detail.clientY-39]
      // console.log(evt)

    });


    // création du overlay pour le résumé d'un reseau
    this.feature_mousemove_overlay = new Overlay({
      positioning: 'top-left',
      offset: [15, 15],
      element: document.getElementById('resume_reseau'),
      stopEvent: false
    });
    map.addOverlay(this.feature_mousemove_overlay);

    this.BackendApiService.getJSON("./assets/tout_tram.json").subscribe(config => {
      var extent = config.extent

      var identifiant_fdp_qgis = config.identifiant_fdp_qgis
      this.ImpressionComp.initialise_impression(config.url_qgis_serveur,extent,identifiant_fdp_qgis)
      var nombre_couches = 2

      for (let index = 0; index < config["reseaux"].length; index++) {
        for (let j = 0; j < config["reseaux"][index]["reseaux"].length; j++) {
          nombre_couches = nombre_couches + 1
        }
      }
      this.big_loading['nombre_couches'] = nombre_couches
      this.big_loading['nombre_charges'] = 1
      // var extent = [211995.59192271344363689, 6612537.44348784815520048, 213855.86339193358435296, 6612980.81131050176918507]
      

      var size = [map.getSize()[0] - 356, map.getSize()[1]]

      map.getView().fit(extent, { 'size': size, 'padding': [200, 0, 0, -356 * 2]})
      // map.getView().fit(extent, { 'size': size})
      // map.getView().setZoom(19)
      // SERVICE=WFS&VERSION=1.1.0&REQUEST=GETFEATURE&typeName=Universit_&outputFormat=GeoJSON

      // var emprise = {
      //   'url': config.prefix_url,
      //   'identifiant_wms': config.identifiant_emprise_wms,
      //   'nom': 'emprise',
      //   'type':'wms',
      //   'active':true
      // }

      var styles_emprise = [
        // linestring
        new Style({
          stroke: new Stroke({
            color: '#0000008a',
            width: 3.5
          })
        })
      ];

      this.emprise = {
        'url': config.url_qgis_serveur + "&SERVICE=WFS&VERSION=1.1.0&REQUEST=GETFEATURE&typeName=" + config.identifiant_emprise_wms + "&SRSNAME=EPSG:3857",
        'nom': 'emprise',
        'identifiant_qgis': 'emprise',
        'type_layer': 'emprise',
        'type': 'wfs',
        'crs': 'EPSG:4326',
        'active': true,
        'visible': true,
        'strategy': "wfs",
        'style': styles_emprise
      }
      if (config.identifiant_emprise_wms == 'emprise'){
        this.gestion_carto(this.emprise)
      }
      var styles_fdp = [
        // linestring
        new Style({
          stroke: new Stroke({
            color: '#0000008a',
            width: 1,
            // lineDash: [20, 0, 20],
            // lineCap:"round"
            // lineDashOffset:10
          })
        })
      ];

      var identifiant_fdp_wfs = config.identifiant_fdp_tms.replace('_web', '')
    //  console.log(config.identifiant_fdp_tms,identifiant_fdp_wfs)
      // .replace(/ /g, '_')
      this.couche_fdp = {
        // 'url': config.prefix_url,
        'url': config.url_qgis_serveur + "&SERVICE=WFS&VERSION=1.1.0&REQUEST=GETFEATURE&typeName=" +identifiant_fdp_wfs + "&SRSNAME=EPSG:3857",
        'url_tms':config.prefix_url+'wmts/',
        'nom': 'fdp',
        'type_layer': 'fdp',
        'identifiant_tms': config.identifiant_fdp_tms.replace('_web', '').replace(/_/g, '')+'_web',
        'type': 'groupe',
        'crs': 'EPSG:4326',
        'active': true,
        'visible': true,
        'strategy': "all",
        'style': styles_fdp, 
        'load': false
      }
      // this.gestion_carto(emprise)
      this.gestion_carto(this.couche_fdp)

      for (let index = 0; index < config["reseaux"].length; index++) {
        for (let j = 0; j < config["reseaux"][index]["reseaux"].length; j++) {
          config["reseaux"][index]["reseaux"][j]['active'] = true
          config["reseaux"][index]["reseaux"][j]['visible'] = true
          config["reseaux"][index]["reseaux"][j]['type_layer'] = 'reseaux'
          config["reseaux"][index]["reseaux"][j]['reseaux'] = config["reseaux"][index]["nom"]

          var type = 'groupe'
          if (type == 'wms') {
            config["reseaux"][index]["reseaux"][j]['url'] = config.prefix_url
            config["reseaux"][index]["reseaux"][j]['type'] = type
          } else {


            var styles_rsx = [
              // linestring
              new Style({
                stroke: new Stroke({
                  color: config["reseaux"][index].couleur,
                  width: 2,
                  // lineDash: [20, 0, 20],
                  // lineCap:"round"
                  // lineDashOffset:10
                })
              })
            ];
            
            
            var identifiant_tms = config["reseaux"][index]["reseaux"][j]['identifiant_tms'].replace('_web', '').replace(/_/g, '')+'_web'
            var identifiant_qgis = config["reseaux"][index]["reseaux"][j]['identifiant_qgis']
            var identifiant_wfs = config["reseaux"][index]["reseaux"][j]['identifiant_tms'].replace('_web', '').replace(/ /g, '_')
            // 'GRAND LYON RESEAU EXPLOITANTS_TEL_web'.replace('_web', '').replace(/ /g, '_') GRAND_LYON_RESEAU_EXPLOITANTSTEL
            // console.log(identifiant_wfs)
            config["reseaux"][index]["reseaux"][j]['url'] = config.url_qgis_serveur + "&SERVICE=WFS&VERSION=1.1.0&REQUEST=GETFEATURE&typeName=" + identifiant_wfs + "&SRSNAME=EPSG:3857"
            config["reseaux"][index]["reseaux"][j]['url_tms'] = config.prefix_url+'wmts/'
            config["reseaux"][index]["reseaux"][j]['type'] = type
            config["reseaux"][index]["reseaux"][j]['identifiant_tms'] = identifiant_tms
            config["reseaux"][index]["reseaux"][j]['crs'] = 'EPSG:4326'
            config["reseaux"][index]["reseaux"][j]['strategy'] = 'all'
            config["reseaux"][index]["reseaux"][j]['couleur'] = config["reseaux"][index].couleur
            config["reseaux"][index]["reseaux"][j]['style'] = 'style_reseaux'


          }

          this.gestion_carto(config["reseaux"][index]["reseaux"][j])
        }
      }

      this.config = config
      this.UndermapReseauxService.setConfig(this.config,map)
      this.mesureComp.setMapInstance(map)
      this.mesureComp.initialise_mesure()

    });

    var resolutions = [];
    var matrixIds = [];
    var proj3857 = getProjection('EPSG:3857');
    var maxResolution = getWidth(proj3857.getExtent()) / 256;

    for (var i = 0; i < 21; i++) {
      matrixIds[i] = i.toString();
      resolutions[i] = maxResolution / Math.pow(2, i);
    }

    var tileGrid = new WMTSTileGrid({
      origin: [-20037508, 20037508],
      resolutions: resolutions,
      matrixIds: matrixIds
    });

    var ign_source = new WMTS({
      url: 'https://wxs.ign.fr/pratique/geoportail/wmts',
      layer: 'ORTHOIMAGERY.ORTHOPHOTOS',
      // layer: 'GEOGRAPHICALGRIDSYSTEMS.MAPS',pratique choisirgeoportail http://wxs.ign.fr/lrxqwx0cakcvlf8bjyimumhx/wmts?LAYER=TRANSPORTNETWORKS.ROADS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&&TILEMATRIX=18&TILECOL=132834&TILEROW=90195
      matrixSet: 'PM',
     
      format: 'image/jpeg',
      projection: 'EPSG:3857',
      tileGrid: tileGrid,
      style: 'normal',
      crossOrigin:'anonymous',
      attributions: '<a href="http://www.geoportail.fr/" target="_blank">' +
        '<img src="https://api.ign.fr/geoportail/api/js/latest/' +
        'theme/geoportal/img/logo_gp.gif"></a>'
    });

    var ign = new TileLayer({
      source: ign_source,
      minResolution: map.getView().getResolutionForZoom(21),
    });

    

    var googleLayerSatellite = new TileLayer({
      title: "Google Satellite",
      source: new TileImage({ url: 'http://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}' }),
    });

    var googleLayerSatellite = new TileLayer({
      visible: false,
      preload: Infinity,
     
      source: new BingMaps({
        key: 'AkTWzlIeIsipy_YZuP9PfYZ_uHCs4eaNHXtCtINtSR1KRW_KSBgTyPkrdhq01IVa',
        imagerySet: 'Aerial',
        maxZoom: 19,
        // use maxZoom 19 to see stretched tiles instead of the BingMaps
        // "no photos at this zoom level" tiles
        // maxZoom: 19
      })
    })

    // googleLayerSatellite.set('nom', 'aeriene')

    ign  = this.MapGeneralService.wmsLyon
    ign.set('nom', 'aeriene')
    ign.set('type', 'fond')
    ign.setVisible(true)
    map.addLayer(ign);
    // map.addLayer(ign);
    // console.log(this.MapGeneralService.wmsLyon)

    
   
      // var url = "http://tilespr.undermap.me/cgi-bin/qgis_mapserv.fcgi?SERVICE=WMS&VERSION=1.3.0&map=/var/www/projet_qgis/egis_tram/TRONCON_2/TRAM_T6_TRONCON_2.qgz&REQUEST=GetPrint&CRS=EPSG:3857&FORMAT=pdf&map0:EXTENT="+bbox.join(',')+"&map0:LAYERS=GRAND_LYON_RESEAU_EXPLOITANTS_75cd7b49_1e9a_424c_a923_0e45e81e9b41,GRDF_4342c0dd_ce87_4b7e_981b_b9cbfaccdfda&TEMPLATE=default&DPI=800"
    
    // http://tilespr.undermap.me/cgi-bin/qgis_mapserv.fcgi?SERVICE=WMS&VERSION=1.3.0&map=/var/www/projet_qgis/egis_tram/TRONCON_2/TRAM_T6_TRONCON_2.qgz&REQUEST=GetPrint&CRS=EPSG:3857&FORMAT=pdf&map0:EXTENT=543461.8405946865,5741020.742853203,544567.7142162031,5741952.002536932&map0:LAYERS=FDP_TRONCON_2_66c19c78_6b42_4a50_a1c0_7a6475c9ef9b,GRDF_4342c0dd_ce87_4b7e_981b_b9cbfaccdfda&TEMPLATE=default&DPI=800
  }

   

  ngAfterViewChecked() {
    
    // console.log(document.getElementById("ghost"),document.getElementById("legende").offsetHeight)
    // document.getElementById("ghost").style.height = document.getElementById("legende").offsetHeight+"px"

  }

  gestion_carto(couche) {
    if (couche.active) {

      if (couche.type == "wms") {

        var wmsSource = new ImageWMS({
          url: couche.url,
          params: { 'LAYERS': couche.identifiant_wms },
          serverType: 'qgis',
          crossOrigin: 'anonymous',
          transition: 0
        });

        var wmsLayer = new ImageLayer({
          source: wmsSource,
          nom: couche.nom
        });

        wmsLayer.set('nom', couche.nom)
        // wmsLayer.set('type_layer', couche.type_layer)
        map.addLayer(wmsLayer);
      } else if (couche.type == "wfs") {

        var vectorSource = new VectorSource({
          format: new WFS({
            srsName: 'EPSG:3857'
          }),
          projection: 'EPSG:3857',
          loader: (extent, resolution, projection) => {
            var Amin = Transform([extent[0], extent[1]], 'EPSG:3857', couche.crs)
            var Amax = Transform([extent[2], extent[3]], 'EPSG:3857', couche.crs)

            var extend4326 = [Amin[0], Amin[1], Amax[0], Amax[1]]
            var url = decodeURI(couche.url + '&bbox=' + extent.join(','));

            $('.loading').show()
            this.BackendApiService.getGml(url).subscribe(data => {
              this.big_loading['nombre_charges'] = this.big_loading['nombre_charges'] + 1
              vectorSource.addFeatures(vectorSource.getFormat().readFeatures(data))
              $('.loading').hide()
            })
          },
          strategy: bboxStrategy
        });

        var wfsLayer = new VectorLayer({
          source: vectorSource,
          style: couche.style,
          type_layer:couche.type_layer,
          nom:couche.nom,
          identifiant_qgis:couche.identifiant_qgis,
        });

        wfsLayer.set('type_layer', couche.type_layer)
        wfsLayer.set('identifiant_qgis', couche.identifiant_qgis)
        wfsLayer.set('nom', couche.nom)
        wfsLayer.setVisible(couche.visible)
        map.addLayer(wfsLayer);

      } else if (couche.type == "wmts") {
        console.log(4)

        var projection = getProjection('EPSG:3857');
        var projectionExtent = projection.getExtent();
        var size = getWidth(projectionExtent) / 256;
        var resolutions = new Array(22);
        var matrixIds = new Array(22);
        for (var z = 0; z < 22; ++z) {
         
          // generate resolutions and matrixIds arrays for this WMTS
          resolutions[z] = size / Math.pow(2, z);
          matrixIds[z] = z;
        }

        this.big_loading['nombre_charges'] = this.big_loading['nombre_charges'] + 1
        var wmts_source = new WMTS({
          url: "http://tuilespr.undermap.me/troncon_1_egis_config/wmts/" +couche.identifiant_wms+ "/webmercator/{TileMatrix}/{TileCol}/{TileRow}.png",
          // layer: couche.identifiant_wms,
          matrixSet: 'webmercator',
          format: 'image/png',
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds
          }),
          // tileLoadFunction:(imageTile, src)=>{
          //   imageTile.getImage().src = src;
          //   console.log(1)
          // },
          style: 'default',
          requestEncoding: 'REST',
          wrapX: true
        });
  
        var wmts_layer = new TileLayer({
          source: wmts_source
        });
        map.addLayer(wmts_layer)
      }else if(couche.type == "groupe"){

        var projection = getProjection('EPSG:3857');
        var projectionExtent = projection.getExtent();
        var size = getWidth(projectionExtent) / 256;
        var resolutions = new Array(22);
        var matrixIds = new Array(22);
        for (var z = 0; z < 22; ++z) {
         
          // generate resolutions and matrixIds arrays for this WMTS
          resolutions[z] = size / Math.pow(2, z);
          matrixIds[z] = z;
        }
        var wmts_source = new WMTS({
          url: couche.url_tms +couche.identifiant_tms.replace(/ /g, '')+ "/webmercator/{TileMatrix}/{TileCol}/{TileRow}.png",
          // layer: couche.identifiant_wms,
          matrixSet: 'webmercator',
          format: 'image/png',
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds
          }),
          style: 'default',
          requestEncoding: 'REST',
          crossOrigin: 'anonymous'
        });
  
        var wmts_layer = new TileLayer({
          source: wmts_source,
          type_layer:couche.type_layer,
          nom:couche.nom,
          identifiant_qgis:couche.identifiant_qgis,
          // preload:21,
          minResolution: map.getView().getResolutionForZoom(19)
        });

        var vectorSource = new VectorSource({
          format: new WFS({
            srsName: 'EPSG:3857'
          }),
          projection: 'EPSG:3857',
          loader: (extent, resolution, projection) => {
            var Amin = Transform([extent[0], extent[1]], 'EPSG:3857', couche.crs)
            var Amax = Transform([extent[2], extent[3]], 'EPSG:3857', couche.crs)

            var extend4326 = [Amin[0], Amin[1], Amax[0], Amax[1]]
            var url = decodeURI(couche.url + '&bbox=' + extent.join(','));

            $('.loading').show()
            this.BackendApiService.getGml(url).subscribe(data => {
              this.big_loading['nombre_charges'] = this.big_loading['nombre_charges'] + 1
              vectorSource.addFeatures(vectorSource.getFormat().readFeatures(data))
              $('.loading').hide()
            })
          },
          strategy: bboxStrategy
        });

        var listenerKey = vectorSource.on('change', (e) => {
          if (vectorSource.getState() == 'ready') {
            if (couche.nom == "fdp") {
              this.couche_fdp["load"] = true
            } else if (couche.style == "style_reseaux") {
              for (let index = 0; index < vectorSource.getFeatures().length; index++) {
                var feature = vectorSource.getFeatures()[index]
                feature.setProperties({ 'couleur': couche.couleur })

              }
            }
          }
        });

        if (couche.style != "style_reseaux") {
          var wfsLayer = new VectorLayer({
            source: vectorSource,
            style: couche.style,
            type_layer:couche.type_layer,
            nom:couche.nom,
            identifiant_qgis:couche.identifiant_qgis,
            maxResolution: map.getView().getResolutionForZoom(19),
            
          });
        } else {

          var wfsLayer = new VectorLayer({
            source: vectorSource,
            style: this.UndermapReseauxService.lineStyleFunction,
            type_layer:couche.type_layer,
            nom:couche.nom,
            identifiant_qgis:couche.identifiant_qgis,
            maxResolution: map.getView().getResolutionForZoom(19),
          });


        }

        var layer_group = new LayerGroup({
          layers: [
            wmts_layer,
            wfsLayer
          ]
        })
        
        if (couche.type_layer == 'reseaux') {
          wfsLayer.set('reseaux', couche.reseaux)
          wmts_layer.set('reseaux', couche.reseaux)
        }
        layer_group.set('type_layer', couche.type_layer)
        layer_group.set('identifiant_qgis', couche.identifiant_qgis)
        layer_group.set('nom', couche.nom)
        layer_group.setVisible(couche.visible)
        map.addLayer(layer_group);

      }
    } else {
      var layer_to_remove;
      for (let index = 0; index < map.getLayers().getArray().length; index++) {
        var layer = map.getLayers().getArray()[index]


        if (layer.get('nom') == couche.nom) {
          layer_to_remove = layer
        }
      }
      // layer_to_remove.setVisible(false)
      // map.removeLayer(layer_to_remove)
    }

  }

 

  mesure() {
    if (this.mesureComp.status_mesure) {
      this.mesureComp.stop_mesure()
    } else {
      this.mesureComp.start_mesure()
    }
   
  }


  goToLink(url: string) {
    console.log(5)
    window.open(url, "_blank");
  }

  set_all_couche_visible(type_reseau){
    this.UndermapReseauxService.set_all_couche_visible(type_reseau)
  }
  type_reseauPartiallySelected(type_reseau){
    return this.UndermapReseauxService.type_reseauPartiallySelected(type_reseau)
  }
  type_reseauAllSelected(type_reseau){
    return this.UndermapReseauxService.type_reseauAllSelected(type_reseau)
  }

}

