import { Injectable } from '@angular/core';
const mdp = 'undermap2019'
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { 
    if (localStorage.getItem('loginDate')) {
      var date1 = new Date();
      var date2 = new Date(localStorage.getItem('loginDate')) ;
      var diffTime = Math.abs(date2.getTime() - date1.getTime());
      // var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // en jour
      var diffMinutes = Math.ceil(diffTime / (1000 * 60 )); // minutes
      if(diffMinutes >= 15){
        this.logout()
      }
    }
  }
  
  loggin(password){
    if (mdp == password) {
      localStorage.setItem('isLoggedIn','true')
      localStorage.setItem('loginDate',new Date().toString())
      return true
    } else {
      return false
    }
  }

  logout(){
    localStorage.setItem('isLoggedIn','false')
    localStorage.removeItem('loginDate')
  }

  isLoggedIn() {
    var isLoggedIn = localStorage.getItem('isLoggedIn')
    if(isLoggedIn != null && isLoggedIn != 'false' ){
      return true
    }else{
      return false
    }
  }
}
