import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators'

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
      private AuthService: AuthService, 
      private router: Router
    ) {}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      //debugger
      if(this.AuthService.isLoggedIn()) {
        return true
      }else{
        this.router.navigate(['login']) 
      }
    //   return this.user.isLoggedIn().pipe(map(res => {
    //     if(res.status) {
    //       this.AuthService.setLoggedIn(true)
    //       return true
    //     } else {
    //       this.router.navigate(['login'])
    //       return false
    //     }
    //   }))
  }
}