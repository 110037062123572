import { Component, OnInit } from '@angular/core';

import 'ol/ol.css';
import { Map, View } from 'ol';
import { Vector as VectorLayer } from 'ol/layer.js';
import { transform as Transform, fromLonLat, get as getProjection } from 'ol/proj.js';
import VectorSource from 'ol/source/Vector.js';
import { Circle as CircleStyle, Fill, Stroke, Text, RegularShape } from 'ol/style.js';
import Style from 'ol/style/Style';
import BaseObject from 'ol/Object';
import Overlay from 'ol/Overlay.js';
import LineString from 'ol/geom/LineString';
import { unByKey } from 'ol/Observable.js';
import { getArea, getLength } from 'ol/sphere.js';
import { Polygon } from 'ol/geom.js';
import Draw from 'ol/interaction/Draw.js';

import * as $ from 'jquery';


@Component({
  selector: 'app-mesure',
  templateUrl: './mesure.component.html',
  styleUrls: ['./mesure.component.scss']
})
export class MesureComponent implements OnInit {

  constructor() { }

  map : Map
  vector_dessin
  source_dessin

  /**
 * Currently drawn feature.
 * @type {module:ol/Feature~Feature}
 */
  sketch;
  pointermove_event
  a :String

  /**
   * The help tooltip element.
   * @type {Element}
   */
  helpTooltipElement;


  /**
   * Overlay to show the help messages.
   * @type {module:ol/Overlay}
   */
  helpTooltip;


  /**
   * The measure tooltip element.
   * @type {Element}
   */
  measureTooltipElement;


  /**
   * Overlay to show the measurement.
   * @type {module:ol/Overlay}
   */
  measureTooltip;


  /**
   * Message to show when the user is drawing a polygon.
   * @type {string}
   */
  continuePolygonMsg = 'Cliquer pour continuer à mesurer';


  /**
   * Message to show when the user is drawing a line.
   * @type {string}
   */
  continueLineMsg = 'Clic-droit pour terminer et "Echap" pour arrêter de dessiner';


  typeSelect = 'length';

  draw; // global so we can remove it later

  status_mesure = false
  formatLength(line) {
    var length = getLength(line);
    var output;
    if (length > 100) {
      output = (Math.round(length / 1000 * 100) / 100) +
        ' ' + 'km';
    } else {
      output = (Math.round(length * 100) / 100) +
        ' ' + 'm';
    }
    return output;
  };

  formatArea(polygon) {
    var area = getArea(polygon);
    var output;
    if (area > 10000) {
      output = (Math.round(area / 1000000 * 100) / 100) +
        ' ' + 'km<sup>2</sup>';
    } else {
      output = (Math.round(area * 100) / 100) +
        ' ' + 'm<sup>2</sup>';
    }
    return output;
  };

  addInteraction() {
    var type = (this.typeSelect == 'area' ? 'Polygon' : 'LineString');
    this.draw = new Draw({
      source: this.source_dessin,
      type: type,
      condition:function(e) {
        console.log(e.pointerEvent.buttons)
        if (e.pointerEvent.buttons === 2) {
          return false;
        } else{
          return true
        }
      },
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)'
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)'
          })
        })
      })
    })

    this.map.addInteraction(this.draw);

    this.createMeasureTooltip();
    this.createHelpTooltip();

    var listener;
    this.draw.on('drawstart',
       (evt) =>{
        // set sketch
        this.sketch = evt.feature;

        /** @type {module:ol/coordinate~Coordinate|undefined} */
        var tooltipCoord = evt.coordinate;

        listener = this.sketch.getGeometry().on('change', (evt)=> {
          var geom = evt.target;
          var output;
          if (geom instanceof Polygon) {
            output = this.formatArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            output = this.formatLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          }
          this.measureTooltipElement.innerHTML = output;
          this.measureTooltip.setPosition(tooltipCoord);
        });
      });

      this.draw.on('drawend',() =>{
        this.measureTooltipElement.className = 'tooltip tooltip-static';
        this.measureTooltip.setOffset([0, -7]);
        // unset sketch
        this.sketch = null;
        // unset tooltip so that a new one can be created
        this.measureTooltipElement = null;
        this.createMeasureTooltip();
        unByKey(listener);
      });
  }

  createHelpTooltip() {
    if (this.helpTooltipElement) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
    }
    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: 'center-left'
    });
    this.map.addOverlay(this.helpTooltip);
  }

  createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'tooltip tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });
    this.map.addOverlay(this.measureTooltip);
  }

  pointerMoveHandler(evt) {
    if (evt.dragging) {
      return;
    }
    /** @type {string} */
    var helpMsg = 'Cliquez pour commencer à mesurer';

    if (this.sketch) {
      var geom = (this.sketch.getGeometry());
      if (geom instanceof Polygon) {
        helpMsg = this.continuePolygonMsg;
      } else if (geom instanceof LineString) {
        helpMsg = this.continueLineMsg;
      }
    }

    this.helpTooltipElement.innerHTML = helpMsg;
    this.helpTooltip.setPosition(evt.coordinate);

    this.helpTooltipElement.classList.remove('hidden');
  }
  ngOnInit() {

    

  }

  setMapInstance(map){
    this.map = map

    this.map.getViewport().addEventListener('contextmenu',  (evt)=> {
      evt.preventDefault();
      if (this.draw) {
        this.draw.finishDrawing();
      }
    })

    // var keydown = function(evt){
    //   var charCode = (evt.which) ? evt.which : evt.keyCode;
    //   if (charCode === 27 && drawing === true){ //esc key
    //       //dispatch event
    //       this.stop_mesure()
    //       draw.set('escKey', Math.random());
    //   }
    // };
  
    document.addEventListener('keydown', (evt)=>{
      var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode === 27 && this.draw){ 
          this.stop_mesure()
        }
    }, false);

  }

  initialise_mesure(){
    this.source_dessin = new VectorSource();

    this.vector_dessin = new VectorLayer({
      source: this.source_dessin,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: 'red',
          width: 2
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33'
          })
        })
      })
    });

    this.map.addLayer(this.vector_dessin)
    

  }

  start_mesure(){
    this.typeSelect = 'length'
    this.addInteraction();

    this.pointermove_event = this.map.on('pointermove', (evt) => {
      this.pointerMoveHandler(evt)
    });

    this.map.getViewport().addEventListener('mouseout', () => {
      this.helpTooltipElement.classList.add('hidden');
    });

    this.status_mesure = true
  }

  stop_mesure(){
    this.map.removeInteraction(this.draw);
    
    // $('.ol-overlay-container.ol-selectable').remove()

    // $('.ol-overlay-container.ol-selectable').remove(".tooltip")
    this.helpTooltipElement.classList.add('hidden');
    
    this.map.removeOverlay(this.helpTooltip)
    this.map.removeOverlay(this.measureTooltip)
    this.map.getViewport().removeEventListener('mouseout', () => {
      this.helpTooltipElement.classList.add('hidden');
    });
    unByKey(this.pointermove_event)
    // console.log(this.measureTooltipElement.parentNode) 
    // this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    this.source_dessin.clear()
    $('.tooltip.tooltip-static').remove()

    this.status_mesure = false
  }
}
